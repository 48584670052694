import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "delete-a-content-items-schedule"
    }}>{`Delete a Content Item's Schedule`}</h1>
    <hr></hr>
    <p>{`To delete a schedule for a content item, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Content section from the left side menu options. `}</li>
      <li parentName="ol">{`Find the content item you wish to delete the schedule from and select it from the list. `}</li>
      <li parentName="ol">{`Scroll to the bottom of the page, select the channel or channels you want to remove the schedule from.`}</li>
      <li parentName="ol">{`Select 'Delete'. `}</li>
      <li parentName="ol">{`Confirm or cancel the deletion. `}</li>
      <li parentName="ol">{`Click 'Save' to apply the changes to your mobile app. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      